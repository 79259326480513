import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function useNewsAndDocuments() {
  const newsData = ref([])
  const perPage = ref(10)
  const newsStatuses = [
    { value: '0', label: 'Не опубликовано' },
    { value: '1', label: 'Опубликовано' },
    { value: '1000', label: 'Все' },
  ]
  const xparam1 = ref('1000')
  const xparam2 = ref('100')
  const xparam3 = ref('Standart')
  const total = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const newsSidebar = ref({
    categories: [
      {
        value: '0',
        label: 'Новость',
        color: 'light-primary',
        icon: 'GridIcon',
      },
      {
        value: '1',
        label: 'Акция',
        color: 'light-danger',
        icon: 'CalendarIcon',
      },
      {
        value: '2',
        label: 'Мероприятия',
        color: 'light-success',
        icon: 'BriefcaseIcon',
      },
      {
        value: '3',
        label: 'Программа',
        color: 'light-secondary',
        icon: 'AirplayIcon',
      },
    ],
    tags: [
      {
        value: 'Standart',
        label: 'Standart',
      },
      {
        value: 'Slider',
        label: 'Slider',
      },
      {
        value: 'video',
        label: 'Video',
      },
    ],
    recentPosts: [],
  })

  const fetchNews = () => {
    store.dispatch('app-news/fetchNews', {
      xparam1: xparam1.value,
      xparam2: xparam2.value,
      xparam3: xparam3.value,
      currentPage: currentPage.value,
      offset: perPage.value * (currentPage.value - 1),
      perPage: perPage.value,
      searchQuery: searchQuery.value,
      sortby: sortBy.value,
      isSortDirDesc: isSortDirDesc.value,
    })
      .then(response => {
        const { data } = response.data
        newsData.value = data.rows
        total.value = data.paging.total || 0
      })
      .catch(() => {
        newsData.value = undefined
      })
  }

  const fetchRecentNews = () => {
    store.dispatch('app-news/fetchNews', {
      currentPage: 1,
      offset: 0,
      perPage: 4,
    })
      .then(response => {
        const { data } = response.data
        newsSidebar.value.recentPosts = data.rows
      })
      .catch(() => {
        newsSidebar.value.recentPosts = []
      })
  }

  const deleteNews = id => {
    store.dispatch('app-news/deleteNews', id).then(res => {
      if (res) {
        fetchNews()
      }
    })
  }

  const updateNewsStatus = item => {
    store.dispatch('app-news/updateNewsStatus', item).then(res => {
      if (res) {
        fetchNews()
      }
    })
  }

  watch([xparam1, xparam2, xparam3, currentPage], () => {
    fetchNews()
  })

  return {
    newsData,
    perPage,
    currentPage,
    total,
    searchQuery,
    sortBy,
    isSortDirDesc,
    newsSidebar,

    newsStatuses,
    xparam1,
    xparam2,
    xparam3,

    fetchNews,
    deleteNews,
    updateNewsStatus,
    fetchRecentNews,
  }
}
